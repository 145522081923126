/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Title } from '../components/Layout';
import { enrollmentResolution } from '../helpers/enrollment';

const EnrollmentResolution = ({ resolution }) => {
  const { title, body, icon } = enrollmentResolution(resolution);

  return (
    <Container variant="primary">
      <Container maxWidth="sm">
        <Stack alignItems="center" justifyContent="center" spacing={4} mt={2}>
          {icon}
          <Title title={title} />
          <Box component="span">
            <Typography
              variant="h2"
              align="center"
              color="secondary"
              sx={{ width: '520px', lineHeight: '34px' }}
            >
              {body}
            </Typography>
          </Box>
        </Stack>
      </Container>
    </Container>
  );
};

EnrollmentResolution.propTypes = {
  resolution: PropTypes.oneOf(['success', 'failed']).isRequired,
};

export default EnrollmentResolution;
