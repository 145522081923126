import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { prettifyRut, calculateDv } from 'react-rut-formatter';
import { useMutation, useReactiveVar } from '@apollo/client';
import useGetUser from './useGetUser';
import { UPLOAD_CREDENTIALS } from '../graphql/mutations';
import informParent from '../helpers/post-message';
import { FAILED_ENROLLMENT_MESSAGE, SUCCESSFUL_ENROLLMENT_MESSAGE } from '../constants/enrollment';
import { enrollmentErrorMessage } from '../helpers/enrollment';
import { accessGrantedVar } from '../graphql/reactive-variables';

const useEnroll = () => {
  const navigate = useNavigate();
  const accessGranted = useReactiveVar(accessGrantedVar);
  const user = useGetUser();
  const rut = prettifyRut(
    `${user?.selectedCompany?.rut}-${calculateDv(user?.selectedCompany?.rut)}`,
  );
  const [password, setPassword] = useState('');
  const [credentialsError, setCredentialsError] = useState(false);
  const [credentialsErrorMessage, setCredentialsErrorMessage] = useState('');

  const [uploadCredentials, { loading }] = useMutation(UPLOAD_CREDENTIALS, {
    variables: {
      username: rut,
      password,
      companyId: user?.selectedCompany?.id,
    },
    onCompleted: () => {
      if (accessGranted) {
        informParent(SUCCESSFUL_ENROLLMENT_MESSAGE);
        accessGrantedVar(false);
        navigate('success');
      }
    },
    onError: ({ message }) => {
      const errorMessage = enrollmentErrorMessage(message);
      setCredentialsError(true);
      setCredentialsErrorMessage(errorMessage);
      informParent(FAILED_ENROLLMENT_MESSAGE(errorMessage));
    },
  });

  const disabled = !password || !user;

  const enroll = useCallback(() => {
    if (process.env.REACT_APP_TEST_COMPANIES.split(',').includes(user?.selectedCompany?.rut)) {
      informParent(SUCCESSFUL_ENROLLMENT_MESSAGE);
      navigate('success');
      return;
    }
    uploadCredentials();
  }, [user, uploadCredentials, navigate]);

  return [
    enroll,
    {
      disabled,
      loading,
      error: credentialsError,
      errorMessage: credentialsErrorMessage,
      rut,
      password,
      setPassword,
    },
  ];
};

export default useEnroll;
