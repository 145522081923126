import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { acceptedTermsVar } from '../../graphql/reactive-variables';
import { CheckboxIcon, CheckboxBorderIcon } from '../../assets/icons';

const AcceptedTermsForm = ({ checkboxProps, labelProps }) => {
  const checked = useReactiveVar(acceptedTermsVar);
  return (
    <FormGroup>
      <FormControlLabel
        control={(
          <Checkbox
            checked={checked}
            color="checkbox"
            icon={<CheckboxBorderIcon />}
            checkedIcon={<CheckboxIcon />}
            onChange={(e) => {
              acceptedTermsVar(e.target.checked);
            }}
            {...checkboxProps}
          />
        )}
        label={(<Typography {...labelProps}>Acepto los Términos y Condiciones</Typography>)}
      />
    </FormGroup>
  );
};

AcceptedTermsForm.propTypes = {
  checkboxProps: PropTypes.shape(),
  labelProps: PropTypes.shape(),
};

AcceptedTermsForm.defaultProps = {
  checkboxProps: {},
  labelProps: {},
};

export default AcceptedTermsForm;
