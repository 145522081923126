import { makeVar } from '@apollo/client';
import { getCookie } from '../helpers/auth';

export const acceptedTermsVar = makeVar(true);

export const accessGrantedVar = makeVar(true);

export const tokenVar = makeVar(getCookie('token'));

export const refreshTokenVar = makeVar(getCookie('refreshToken'));
