const palette = {
  primary: { main: '#EC0000' },
  secondary: { main: '#444444' },
  inputBackground: { main: '#F5F9FB' },
  inputBorder: {
    main: '#257FA4',
    light: '#DEEDF2',
  },
  inputText: {
    main: '#767676',
    secondary: '#127277',
  },
  checkbox: { main: '#127277' },
  gray: { main: '#f0f0f0' },
  white: { main: '#ffffff' },
  divider: { main: '#CCCCCC' },
  disabled: { main: '#f2f2f2' },
};

export default palette;
