import React from 'react';
import { useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { TERMS_AND_CONDITIONS } from '../graphql/queries';
import theme from '../theme';

const TermsAndConditionsDocument = () => {
  const { data, loading } = useQuery(TERMS_AND_CONDITIONS, {
    variables: { countryName: 'Chile' },
  });

  const termsSections = data?.termsAndConditions?.edges?.flatMap((edge) => edge?.node?.sections);
  const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));

  return (
    <Grid container variant={isMobile ? 'terms-and-conditions-mobile' : 'terms-and-conditions'}>
      {loading && (
        <Grid item xs={12} alignSelf="center">
          <CircularProgress color="secondary" />
        </Grid>
      )}
      <Typography variant="body2" alignSelf="center" lineHeight={3} color="secondary">
        TÉRMINOS Y CONDICIONES FINGO
      </Typography>
      {termsSections?.map(({ id, text, sectionType }) => (
        <Grid item key={id} variant="terms-and-conditions-section">
          <Typography align="justify" color="secondary" variant={sectionType}>
            {text}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default TermsAndConditionsDocument;
